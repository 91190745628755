import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_LOGIN

const login = async (user) => {
  try {
    const response = await axios.post(baseUrl, user)
    return response.data

  } catch (exception) {
    return { success: false, message: "Invalid username or password!" }
  }

}

export default { login }
