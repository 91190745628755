import React, { Component } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Notification from './Notification';
import loginService from '.././services/login';
import localStorage from '.././services/localStorage';
import './login.css';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      password: "",
      isHidden: false,
      error: false,
      message: null
    };
  }

  validateForm() {
    return this.state.userId.length > 0 && this.state.password.length > 0;
  }

  returnButtonVisibility() {
    return this.state.isHidden === true;
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { userId, password } = this.state;
    const user = {
      "userId": userId,
      "password": password
    }

    try {
      const result = await loginService.login(user);

      if (result.success === true) {
        const saveTokenToLocal = await localStorage.saveToken(result.token)
        this.props.history.push('/main');

      } else {
        this.setState({
          userId: "",
          password: "",
          message: result.message,
          error: true
        })
        this.timeoutForNotification(7000)
      }

      } catch(exception) {
        this.setState({
          message: exception,
          error: true
        })
      }
  }

  timeoutForNotification = (seconds) => {
    setTimeout(() => {
      this.setState({ message: null })
    }, seconds)
  }

  render() {
    const { userId, password } = this.state;
    return (
        <Container className="Login">
          <Notification message={this.state.message} error={this.state.error} />
          {!this.state.isHidden && (
            <div>
              <h2>Please Identify Yourself</h2>
              <Form className="form" onSubmit={ (e) => this.handleSubmit(e) }>
                <Col>
                  <FormGroup>
                    <Label for="userId">Username</Label>
                    <Input autoFocus type="text" name="userId" value={ userId } onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input type="password" name="password" value={ password } onChange={this.handleChange} />
                  </FormGroup>
                  <Button color="info" disabled={!this.validateForm()}>Login</Button>
                </Col>
              </Form>
            </div>
            )}
        </Container>
    );
  }
}
