const saveToken = (user) => {
  window.localStorage.setItem('user-token', JSON.stringify(user));
}

const getToken = () => {
  const token = window.localStorage.getItem('user-token')
  return token ? JSON.parse(token) : false
}

const removeToken = () => {
  window.localStorage.removeItem('user-token')
}

export default { saveToken, getToken, removeToken }
