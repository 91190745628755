import React, { Component } from 'react';
import { Container, Col, CustomInput, Form, FormGroup, Label, Input, Button } from "reactstrap";
import swal from 'sweetalert';
import userService from '.././services/user';
import localStorage from '.././services/localStorage';
import './userForm.css';

export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      password: '',
      name: '',
      role: ''
    };
  }

  async componentDidMount() {
    try {
      const localToken = await localStorage.getToken();
      await userService.setToken(localToken)

    } catch (execption) {
      console.log(execption)
    }
  }

  validateForm() {
    return this.state.userId.length > 0 && this.state.password.length > 0 && this.state.name.length > 0 && this.state.role.length > 0;
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { userId, password, name, role} = this.state;

    const user = {
      "userId": userId,
      "password": password,
      "name": name,
      "role": role
    }

    try {
      const result = await userService.create(user)

      if (result.success === true) {
        this.setState({
           userId: "",
           password: "",
           name: "",
           role: "",
           isHidden: false,
         })
         swal({
           title: "Yay!",
           text: result.message,
           icon: "success",
         });

      } else {
        swal({
          title: "Oh, Snap!",
          text: result.message,
          icon: "error",
        });
      }

    } catch (execption) {
      this.setState({
        userId: ""
      })
      swal({
        title: "Oh, Snap!",
        text: "UserId must be unique!",
        icon: "error",
      });
    }
  }

  render() {
    const { userId, password, name, role } = this.state;
    return (
      <Container className="UserForm">
          <h2>Add new user</h2>
          <Form className="form" onSubmit={ (e) => this.handleSubmit(e) }>
            <FormGroup row>
                <Label for="userId" sm={2}>UserId</Label>
                <Col sm={10}>
                  <Input
                    autoFocus type="text" name="userId" value={ userId } onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="password" sm={2}>Password</Label>
                <Col sm={10}>
                  <Input type="password" name="password" value={ password } onChange={this.handleChange} />
                </Col>
              </FormGroup>
            <FormGroup row>
                <Label for="name" sm={2}>Name</Label>
                <Col sm={10}>
                  <Input type="text" name="name" value={ name } onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="role" sm={2}>Role</Label>
                <Col sm={10}>
                  <CustomInput type="select" id="role" name="role" value={ role } onChange={this.handleChange}>
                    <option value=""></option>
                    <option>user</option>
                    <option>admin</option>
                  </CustomInput>
                </Col>
            </FormGroup>
            <Button color="info" disabled={!this.validateForm()}>Submit</Button>
          </Form>
      </Container>
    );
  }
}
