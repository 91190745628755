import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import localStorage from '.././services/localStorage';
import userService from '.././services/user';

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }

    async componentDidMount() {
      try {
        const localToken = await localStorage.getToken();
        const token = await userService.checkToken(localToken)

        if (token.success === true) {
          this.setState({ loading: false });

        } else {
          this.setState({ loading: false, redirect: true });
        }
      } catch (exception) {
        this.setState({ loading: false, redirect: true });
      }
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect {...this.props} />
        </React.Fragment>
      );
    }
  }
}
