import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import withAuth from './withAuth';
import Header from './Header';
import Main from './Main';
import Users from './Users';
import UserForm from './UserForm';
import Login from './Login';
import NotFound from './NotFound';

library.add(faTrash, faEdit)

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={ Login } />
          <Route exact path="/main" component={ withAuth(Main) } />
          <Route exact path="/users" component={ withAuth(Users) } />
          <Route exact path="/new" component={ withAuth(UserForm) } />
          <Route path="*" component={ NotFound }/>
        </Switch>
      </div>
    );
  }
}

export default App;
