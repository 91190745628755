import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_USER

let token = null

const getAll = async () => {
  const config = {
    headers: { 'Authorization': token }
  }

  const response = await axios.get(baseUrl, config)
  return response.data
}

const setToken = (newToken) => {
  token = `bearer ${newToken}`
}

const create = async (newObject) => {
  const config = {
    headers: { 'Authorization': token }
  }

  const response = await axios.post(baseUrl, newObject, config)
  return response.data
}

const update = async (id, newObject) => {
  const config = {
    headers: { 'Authorization': token }
  }

  const response = await axios.put(`${baseUrl}/${id}`, newObject, config)
  return response.data
}

const remove = async (id) => {
  const config = {
    headers: { 'Authorization': token }
  }

  const response = await axios.delete(`${baseUrl}/${id}`, config)
  return response.data
}

const checkToken = async (token) => {
  try {
    const response = await axios.post(`${baseUrl}/checkToken`, { 'token': token })
    return response.data

  } catch (execption) {
    return { success: false, message: "Could not validate token!" }
  }
}

export default { getAll, create, update, remove, setToken, checkToken}
