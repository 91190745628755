import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Jumbotron } from "reactstrap";

const Main = () => (
  <Container>
  <Jumbotron>
      <h1 className="display-3">Willkommen!</h1>
      <p className="lead">This is a user management page for Testbank.</p>
      <hr />
      <div className="lead">
        <ButtonGroup>
         <Button tag={Link} to="/users">List all users</Button>
         <Button color="primary" tag={Link} to="/new">Add new user</Button>
        </ButtonGroup>
      </div>
    </Jumbotron>
  </Container>
)

export default Main;
