import React from 'react';
import { Alert } from 'reactstrap';
import './notification.css';

const Notification = ({ message, error }) => {
  if (message === null) {
    return null

  } else if (error) {
    return (
        <Alert color="danger">
        <h4 className="alert-heading">Oh, Snap!</h4>
        <hr />
        <p className="alert-heading"><b>{ message }</b></p>
        </Alert>
    )
  } else {
    return (
        <Alert color="success">
          <h4 className="alert-heading">Yay!</h4>
          <hr />
          <p className="alert-heading"><b>{ message }</b></p>
        </Alert>
    )
  }
}

export default Notification
