import React, { Component } from 'react';
import { Button, ButtonGroup, Container } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './admin.css'
import userService from '.././services/user';
import localStorage from '.././services/localStorage';

export default class Users extends Component {
  constructor(props) {
     super(props)
     this.state = {
       data: [],
       isHidden: false,
       error: false,
       message: null
     }
  }

  async componentDidMount() {
    try {
      const localToken = await localStorage.getToken();

      if (localToken) {
        await userService.setToken(localToken)
        const data = await userService.getAll();
        this.setState(() => ({
          data: data.users
        }))
      } else {
        console.log("Cannot load data, not authenticated")
      }

    } catch (exception) {
      console.log(exception)
    }

  }

  actionsFormatter = (cell, row) => {
    return (
      <div>
      <ButtonGroup className="btn-group mr-2">
        <Button id={row.id} color="danger"><FontAwesomeIcon icon="trash" onClick={ (e) => this.handleDelete(e, row) } /></Button>
      </ButtonGroup>
      </div>
    )
  }

  handleDelete = async (event, item) => {
    event.preventDefault();

    try {
      const result = await userService.remove(item.id);
      if (result.success === true) {
        const data = await userService.getAll();
        this.setState(() => ({
          data: data.users,
        }))
        // TODO move to won function
        swal({
          title: "Yay!",
          text: result.message,
          icon: "success",
        });
      } else {
        swal({
          title: "Oh, Snap!",
          text: result.message,
          icon: "error",
        });
      }

    } catch (execption) {
      console.log(execption)
    }

  }

  onTableChange = () => {
    this.setState({ data: [] })
  }

  afterSaveCell = async (oldValue, newValue, row, column, done) => {
    if (oldValue !== newValue) {
      const toBeUpdatedUser = this.state.data.find(user => user.id === row.id)

      const updatedUser = {
        name: toBeUpdatedUser.name,
        role: toBeUpdatedUser.role,
        identityNumber: toBeUpdatedUser.identityNumber
      }

      try {
        const result = await userService.update(toBeUpdatedUser.id, updatedUser)

        if (result.success === true) {
          const data = await userService.getAll();
          this.setState(() => ({
            data: data.users
          }))
          swal({
            title: "Yay!",
            text: result.message,
            icon: "success",
          });

        } else {
          swal({
            title: "Oh, Snap!",
            text: result.message,
            icon: "error",
          });
        }
      } catch (execption) {
        swal({
          title: "Oh, Snap!",
          text: "Something went wrong..",
          icon: "error",
        });
      }
    }
  }

  render() {
    const { data } = this.state;

    const columns = [{
      dataField: 'userId',
      text: 'UserId',
      sort: true
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
      validator: (newValue, row, column) => {
      if (!newValue) {
        return {
          valid: false,
          message: 'Value cannot be empty'
        }
      }
      return true
    }
    }, {
      dataField: 'role',
      text: 'Role',
      sort: true,
      editor: {
        type: Type.SELECT,
        options: [{
           value: 'user',
           label: 'user'
         }, {
           value: 'admin',
           label: 'admin'
         }]
       }
      }, {
      dataField: 'identityNumber',
      text: 'IdentityNumber',
      sort: true,
      validator: (newValue, row, column) => {
      if (!newValue) {
        return {
          valid: false,
          message: 'Value cannot be empty'
        }
      }
        return true
      }
      }, {
      dataField: 'actions',
      text: 'Actions',
      isDummyField: true,
      formatter: this.actionsFormatter
    }];

    return (
      <Container>
        <BootstrapTable
          bootstrap4
          striped
          hover
          keyField='userId'
          data={ data }
          columns={ columns }
          cellEdit={ cellEditFactory({ mode: 'dbclick', blurToSave: true, afterSaveCell: this.afterSaveCell }) }
          pagination={ paginationFactory() }
          onTableChange={this.onTableChange}
          />
      </Container>
    );
  }
}
